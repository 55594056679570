import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faForward, faBackward } from '@fortawesome/free-solid-svg-icons'

export default function FontAwesome (props) {
  const icon = props.icon;

  function ReturnIcon(icon) {
    switch(icon) {
      case 'play':
        return <FontAwesomeIcon icon={faPlay} />
      case 'pause':
        return <FontAwesomeIcon icon={faPause} />
      case 'forward':
        return <FontAwesomeIcon icon={faForward} />
      case 'backward':
        return <FontAwesomeIcon icon={faBackward} />
    }
  }

  return (
    <>
      {ReturnIcon(icon)}
    </>
  )

}
