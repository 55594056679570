import React, { useEffect } from 'react';
import styled from 'styled-components'

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
`

export default function Mail (props) {

  return (
    <Iframe name="result" allow="midi; geolocation; microphone; camera; display-capture; encrypted-media;" sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation allow-downloads" allowfullscreen="" allowpaymentrequest="" frameborder="0" src="https://mail.bigdeskenergy.com/subscribe">
    </Iframe>
  )
}
