import React, { useEffect, useState, useRef, memo } from 'react'
import styled from 'styled-components'
import capitalize from '../hooks/capitalize'
import WindowsIcon from './WindowsIcon'

const ActiveTab = styled.div`
  box-sizing: border-box;
  background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 5px 5px;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-background-origin: padding-box, padding-box;
  background-origin: padding-box, padding-box;
  -webkit-background-clip: border-box, border-box;
  background-clip: border-box, border-box;
  -webkit-background-size: 10px 10px, 10px 10px;
  background-size: 2px 2px, 2px 2px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
  transform: scaleX(1) scaleY(1) scaleZ(1);
  position: relative;
  display: inline-flex;
  text-align: left;
  height: 30px;
  width: auto;
  font-size: 14px;
  border-style: solid;
  border-width: 2px;
  border-left-color: rgb(5, 6, 8);
  border-top-color: rgb(5, 6, 8);
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  box-shadow: rgb(136,140,143) 1px 1px 0px 0px inset, rgb(223,224,227) -1px 0px 0px 1px inset;
  border-style: solid;
  border-width: 2px;
  max-width: 175px;
  width: 100%;
  padding-left: 5px;
  padding-top: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
`

const OtherTab = styled.div`
  box-sizing: border-box;
  background-color: rgb(206, 208, 207);
  color: rgb(5, 6, 8);
  border-left-color: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(5, 6, 8);
  border-bottom-color: rgb(5, 6, 8);
  box-shadow: rgb(223, 224, 227) 1px 1px 0px 1px inset, rgb(136, 140, 143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  height: 30px;
  width: 100%;
  max-width: 175px;
  font-size: 14px;
  border-style: solid;
  border-width: 2px;
  padding-left: 5px;
  padding-top: 3px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
`

export default function Tab(props) {

  const wrapperRef = useRef(null);
  const maxTabWidth = ((window.innerWidth - 275)/props.totalTabs);
  const tabWidth = maxTabWidth < 175 ? maxTabWidth : 175
  const tabStyle = {
    maxWidth: tabWidth.toString() + 'px',
    width: '100%'
  };

  useEffect(() => {
    document.addEventListener("click", handleClickComponent, false);
    return () => {
      document.removeEventListener("click", handleClickComponent, false);
    };
  }, []);

  function handleClickComponent(event) {
    if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      props.updateActive(props.name);
    }
  };


  return (
    <>
      <div style={tabStyle}>
      {props.active ? <ActiveTab ref={wrapperRef}><WindowsIcon src={props.icon} placement="tab" /> {capitalize(props.name)}</ActiveTab> : <OtherTab ref={wrapperRef} ><WindowsIcon src={props.icon} placement="tab" /> {capitalize(props.name)}</OtherTab>}
      </div>
    </>
  )

}