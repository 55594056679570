/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, setState, useEffect, createContext, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import styled from 'styled-components'
import './index.css';
import Button from './components/Button'
import AppBar from './components/AppBar'
import Startup from './components/Startup'
import updateTheme from './hooks/updateTheme'
import Window from './components/Window'
import uniqueWindows from './hooks/uniqueWindows'
import LogoutScreen from './components/LogoutScreen'
import Store from './components/Store'
import Icon from './components/Icon'

import about_icon from './images/about.png'
import settings_icon from './images/settings.png'
import cd_icon from './images/cd.png'
import documents_icon from './images/documents.png'
import notepad_icon from './images/notepad.png'
import store_icon from './images/store.png'
import pong_icon from './images/pong.png'
import mail_icon from './images/mail.png'

const first = {
  position: 'absolute',
  top: '15px'
};

const second = {
  position: 'absolute',
  top: '105px'
};


const third = {
  position: 'absolute',
  top: '195px'
};

const fourth = {
  position: 'absolute',
  top: '285px'
};

const fifth = {
  position: 'absolute',
  top: '15px',
  left: '90px'
};

const sixth = {
  position: 'absolute',
  top: '105px',
  left: '90px'
};

const seventh = {
  position: 'absolute',
  top: '195px',
  left: '90px'
};

const eighth = {
  position: 'absolute',
  top: '285px',
  left: '90px'
};

function App() {
  // startup should be true when not in dev
  const [startup, setStartup] = React.useState(true);
  const [logout, setLogout] = React.useState(false)
  const [theme, setTheme] = React.useState('(None)')
  const mobile = (window.innerWidth < 500)
  const isSafari = window.safari !== undefined;
  updateTheme(theme);

  // uncomment this to disable right-click
  document.addEventListener('contextmenu', event => event.preventDefault());

  // calculate music height
  let r = (window.innerWidth / window.innerHeight);
  let x = (r <= 3.0) ? r : 3.0;
  let h = (-77.75 * Math.pow(x, 3) + 476.5 * Math.pow(x, 2) - (1000.0 * x) + 835.6) + 240;

  const [maxAttributes, setMaxAttributes] = React.useState({
    x: 0,
    y: 0,
    width: window.innerWidth,
    height: window.innerHeight - (mobile ? 14 : 34)
  })

  // About
  const aboutWidth = mobile ? 250 : 350
  const aboutHeight = mobile ? 565 : 565

  const [showAbout, setShowAbout] = React.useState(false);
  const [aboutAttributes, setAboutAttributes] = React.useState({
    x: ((window.innerWidth - aboutWidth ) * 0.9),
    y: ((window.innerHeight - aboutHeight ) * 0.1),
    width: aboutWidth,
    height: aboutHeight,
    minWidth: aboutWidth,
    minHeight: aboutHeight
  })
  const [maxAbout, setMaxAbout] = React.useState(false)

  // Store
  const storeWidth = mobile ? 250 : 500
  const storeHeight = mobile ? 435 : 585

  const [showStore, setShowStore] = React.useState(true);
  const [storeAttributes, setStoreAttributes] = React.useState({
    x: ((window.innerWidth - storeWidth ) * 0.9),
    y: mobile ? ((window.innerHeight - storeHeight ) * 0.85) : ((window.innerHeight - storeHeight ) * 0.7),
    width: storeWidth,
    height: storeHeight,
    minWidth: storeWidth,
    minHeight: storeHeight
  })
  const [maxStore, setMaxStore] = React.useState(false)

  // Settings
  const settingsWidth = mobile ? 300 : 400
  const settingsHeight = mobile ? 485 : 535

  const [showSettings, setShowSettings] = React.useState(false);
  const [settingsAttributes, setSettingsAttributes] = React.useState({
    x: ((window.innerWidth - settingsWidth ) * 0.45),
    y: ((window.innerHeight - settingsHeight ) * 0.35),
    width: settingsWidth,
    height: settingsHeight,
    minWidth: settingsWidth,
    minHeight: settingsHeight
  })
  const [maxSettings, setMaxSettings] = React.useState(false)

  // Documents
  const documentsWidth = mobile ? 300 : 400
  const documentsHeight = mobile ? 230 : 230

  const [showDocuments, setShowDocuments] = React.useState(false);
  const [documentsAttributes, setDocumentsAttributes] = React.useState({
    x: ((window.innerWidth - documentsWidth ) * 0.1),
    y: ((window.innerHeight - documentsHeight ) * 0.7),
    width: documentsWidth,
    height: documentsHeight,
    minWidth: 300,
    minHeight: documentsHeight
  })
  const [maxDocuments, setMaxDocuments] = React.useState(false)

  // CD Player
  const musicWidth = mobile ? 350 : 550
  const musicHeight = (mobile || isSafari) ? 380 : h

  const [showMusic, setShowMusic] = React.useState(true);
  const [musicAttributes, setMusicAttributes] = React.useState({
    x: ((window.innerWidth - musicWidth ) * 0.15),
    y: mobile ? ((window.innerHeight - documentsHeight ) * 0.2) : ((window.innerHeight - documentsHeight ) * 0.4),
    width: musicWidth,
    height: musicHeight,
    minWidth: musicWidth,
    minHeight: musicHeight
  })
  const [maxMusic, setMaxMusic] = React.useState(false)

  // Notepad
  const notepadWidth = mobile ? 275 : 600
  const notepadHeight = mobile ? 250 : 375

  const [showNotepad, setShowNotepad] = React.useState(false);
  const [notepadAttributes, setNotepadAttributes] = React.useState({
    x: ((window.innerWidth - notepadWidth ) * 0.4),
    y: ((window.innerHeight - notepadHeight ) * 0.8),
    width: notepadWidth,
    height: notepadHeight,
    minWidth: notepadWidth / 2,
    minHeight: notepadHeight / 2
  })
  const [maxNotepad, setMaxNotepad] = React.useState(false)
  const [notepadText, setNotepadText] = React.useState('')

  // Pong
  const pongWidth = 730
  const pongHeight = 588

  const [showPong, setShowPong] = React.useState(false);
  const [pongAttributes, setPongAttributes] = React.useState({
    x: ((window.innerWidth - pongWidth ) * 0.4),
    y: ((window.innerHeight - pongHeight ) * 0.8),
    width: pongWidth,
    height: pongHeight,
    minWidth: pongWidth,
    minHeight: pongHeight,
  })
  const [maxPong, setMaxPong] = React.useState(false)

  // Mail
  const mailWidth = mobile ? 330 : 900
  const mailHeight = mobile ? 615 : 650

  const [showMail, setShowMail] = React.useState(false);
  const [mailAttributes, setMailAttributes] = React.useState({
    x: ((window.innerWidth - mailWidth ) * 0.3),
    y: ((window.innerHeight - mailHeight ) * 0.5),
    width: mailWidth,
    height: mailHeight,
    minWidth: mailWidth,
    minHeight: mailHeight,
  })
  const [maxMail, setMaxMail] = React.useState(false)

  // Windows
  const [tabs, setTabs] = React.useState([{name: 'CD Player', icon: cd_icon},{name: 'store', icon: store_icon}]);
  const [active, setActive] = React.useState('store');


  function debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }

  const debouncedHandleResize = debounce(function handleResize() {
    setMaxAttributes({
      x: 0,
      y: 0,
      height: window.innerHeight,
      width: window.innerWidth
    })
  }, 250)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
        window.removeEventListener('resize', debouncedHandleResize);
      };
  });


  function doneBooting(){
    updateTheme('(None)');
    setStartup(false);
  }

  function openWindow(value){
    switch(value) {
      case 'about':
        setTabs(uniqueWindows([...tabs, {name: 'about', icon: about_icon}]))
        setShowAbout(true)
        break;
      case 'store':
        setTabs(uniqueWindows([...tabs, {name: 'store', icon: store_icon}]))
        setShowStore(true)
        break;
      case 'settings':
        setTabs(uniqueWindows([...tabs, {name: 'settings', icon: settings_icon}]))
        setShowSettings(true)
        break;
      case 'documents':
        setTabs(uniqueWindows([...tabs, {name: 'documents', icon: documents_icon}]))
        setShowDocuments(true)
        break;
      case 'CD Player':
        setTabs(uniqueWindows([...tabs, {name: 'CD Player', icon: cd_icon}]))
        setShowMusic(true)
        break;
      case 'notepad':
        setTabs(uniqueWindows([...tabs, {name: 'notepad', icon: notepad_icon}]))
        setShowNotepad(true)
        break;
      case 'pong':
        setTabs(uniqueWindows([...tabs, {name: 'pong', icon: pong_icon}]))
        setShowPong(true)
        break;
      case 'mail':
        setTabs(uniqueWindows([...tabs, {name: 'mail', icon: mail_icon}]))
        setShowMail(true)
        break;
    }
  }

  function closeWindow(value){
    switch(value) {
      case 'about':
        setShowAbout(false);
        setTabs(tabs.filter(e => e.name !== 'about'));
        setAboutAttributes({
          x: ((window.innerWidth - aboutWidth ) * 0.9),
          y: ((window.innerHeight - aboutHeight ) * 0.1),
          width: aboutWidth,
          height: aboutHeight,
          minWidth: aboutWidth,
          minHeight: aboutHeight
        });
        setMaxAbout(false);
        break;
      case 'store':
        setShowStore(false);
        setTabs(tabs.filter(e => e.name !== 'store'));
        setStoreAttributes({
          x: ((window.innerWidth - storeWidth ) * 0.9),
          y: ((window.innerHeight - storeHeight ) * 0.7),
          width: storeWidth,
          height: storeHeight,
          minWidth: storeWidth,
          minHeight: storeHeight
        });
        setMaxAbout(false);
        break;
      case 'settings':
        setShowSettings(false);
        setTabs(tabs.filter(e => e.name !== 'settings'));
        setSettingsAttributes({
          x: ((window.innerWidth - settingsWidth ) * 0.45),
          y: ((window.innerHeight - settingsHeight ) * 0.35),
          width: settingsWidth,
          height: settingsHeight,
          minWidth: settingsWidth,
          minHeight: settingsHeight
        });
        setMaxSettings(false);
        break;
      case 'documents':
        setShowDocuments(false);
        setTabs(tabs.filter(e => e.name !== 'documents'));
        setDocumentsAttributes({
          x: ((window.innerWidth - documentsWidth ) * 0.1),
          y: ((window.innerHeight - documentsHeight ) * 0.7),
          width: documentsWidth,
          height: documentsHeight,
          minWidth: 300,
          minHeight: documentsHeight
        });
        setMaxDocuments(false);
        break;
      case 'notepad':
        setShowNotepad(false);
        setTabs(tabs.filter(e => e.name !== 'notepad'));
        setNotepadAttributes({
          x: ((window.innerWidth - notepadWidth ) * 0.4),
          y: ((window.innerHeight - notepadHeight ) * 0.8),
          width: notepadWidth,
          height: notepadHeight,
          minWidth: notepadWidth / 2,
          minHeight: notepadHeight / 2
        });
        setMaxNotepad(false);
        break;
      case 'pong':
        setShowPong(false);
        setTabs(tabs.filter(e => e.name !== 'pong'));
        setPongAttributes({
          x: ((window.innerWidth - pongWidth ) * 0.4),
          y: ((window.innerHeight - pongHeight ) * 0.8),
          width: pongWidth,
          height: pongHeight,
          minWidth: pongWidth / 2,
          minHeight: pongHeight / 2
        });
        setMaxPong(false);
        break;
      case 'mail':
        setShowMail(false);
        setTabs(tabs.filter(e => e.name !== 'mail'));
        setMailAttributes({
          x: ((window.innerWidth - mailWidth ) * 0.4),
          y: ((window.innerHeight - mailHeight ) * 0.8),
          width: mailWidth,
          height: mailHeight,
          minWidth: mailWidth / 2,
          minHeight: mailHeight / 2
        });
        setMaxMail(false);
        break;
      case 'CD Player':
        setShowMusic(false);
        setTabs(tabs.filter(e => e.name !== 'CD Player'));
        setMusicAttributes({
          x: ((window.innerWidth - musicWidth ) * 0.15),
          y: ((window.innerHeight - documentsHeight ) * 0.4),
          width: musicWidth,
          height: musicHeight,
          minWidth: musicWidth,
          minHeight: musicHeight
        });
        setMaxMusic(false);
        break;
    }
    if (value === active) {
      setActive('')
    }
  }

  function minimizeWindow(value){
    switch(value) {
      case 'about':
        setShowAbout(false)
        setActive('')
        break;
      case 'store':
        setShowStore(false)
        setActive('')
        break;
      case 'settings':
        setShowSettings(false)
        setActive('')
        break;
      case 'documents':
        setShowDocuments(false)
        setActive('')
        break;
      case 'notepad':
        setShowNotepad(false)
        setActive('')
        break;
      case 'pong':
        setShowPong(false)
        setActive('')
        break;
      case 'music':
        setShowMusic(false)
        setActive('')
        break;
      case 'mail':
        setShowMail(false)
        setActive('')
        break;
    }
  }

   function updateActive(value) {
    switch(value) {
      case 'about':
        setActive('about');
        setShowAbout(true);
        break;
      case 'store':
        setActive('store');
        setShowStore(true);
        break;
      case 'settings':
        setActive('settings');
        setShowSettings(true);
        break;
      case 'documents':
        setActive('documents');
        setShowDocuments(true);
        break;
      case 'notepad':
        setActive('notepad');
        setShowNotepad(true);
        break;
      case 'pong':
        setActive('pong');
        setShowPong(true);
        break;
      case 'CD Player':
        setActive('CD Player');
        setShowMusic(true);
        break;
      case 'mail':
        setActive('mail');
        setShowMail(true);
        break;
    }
  }

  function maxWindow(value){
    switch(value) {
      case 'about':
        setMaxAbout(!maxAbout);
        setShowAbout(false);
        setTimeout(function() {
            setShowAbout(true)
        }, 100)
        break;
      case 'store':
        setMaxStore(!maxStore);
        setShowStore(false);
        setTimeout(function() {
            setShowStore(true)
        }, 100)
        break;
      case 'settings':
        setMaxSettings(!maxSettings);
        setShowSettings(false);
        setTimeout(function() {
            setShowSettings(true)
        }, 100)
        break;
      case 'documents':
        setMaxDocuments(!maxDocuments);
        setShowDocuments(false);
        setTimeout(function() {
            setShowDocuments(true)
        }, 100)
        break;
      case 'notepad':
        setMaxNotepad(!maxNotepad);
        setShowNotepad(false);
        setTimeout(function() {
            setShowNotepad(true)
        }, 100)
        break;
      case 'pong':
        setMaxPong(!maxPong);
        setShowPong(false);
        setTimeout(function() {
            setShowPong(true)
        }, 100)
        break;
      case 'music':
        setMaxMusic(!maxMusic);
        setShowMusic(false);
        setTimeout(function() {
            setShowMusic(true)
        }, 100)
        break;
      case 'mail':
        setMaxMail(!maxMail);
        setShowMail(false);
        setTimeout(function() {
            setShowMail(true)
        }, 100)
        break;

    }
  }

  function updateCoordinates(d, window) {
    switch(window) {
      case 'about':
        if (!maxAbout) {
          setAboutAttributes({
            x: d.x,
            y: d.y,
            width: aboutAttributes.width,
            height: aboutAttributes.height,
            minWidth: aboutAttributes.minWidth,
            minHeight: aboutAttributes.minHeight
          })
        }
        break;
      case 'store':
        if (!maxStore) {
          setStoreAttributes({
            x: d.x,
            y: d.y,
            width: storeAttributes.width,
            height: storeAttributes.height,
            minWidth: storeAttributes.minWidth,
            minHeight: storeAttributes.minHeight
          })
        }
        break;
      case 'settings':
        if (!maxSettings) {
          setSettingsAttributes({
            x: d.x,
            y: d.y,
            width: settingsAttributes.width,
            height: settingsAttributes.height,
            minWidth: settingsAttributes.minWidth,
            minHeight: settingsAttributes.minHeight
          })
        }
        break;
      case 'documents':
        if (!maxDocuments) {
          setDocumentsAttributes({
            x: d.x,
            y: d.y,
            width: documentsAttributes.width,
            height: documentsAttributes.height,
            minWidth: documentsAttributes.minWidth,
            minHeight: documentsAttributes.minHeight
          })
        }
        break;
      case 'notepad':
        if (!maxNotepad) {
          setNotepadAttributes({
            x: d.x,
            y: d.y,
            width: notepadAttributes.width,
            height: notepadAttributes.height,
            minWidth: notepadAttributes.minWidth,
            minHeight: notepadAttributes.minHeight
          })
        }
        break;
      case 'pong':
        if (!maxPong) {
          setPongAttributes({
            x: d.x,
            y: d.y,
            width: pongAttributes.width,
            height: pongAttributes.height,
            minWidth: pongAttributes.minWidth,
            minHeight: pongAttributes.minHeight
          })
        }
        break;
      case 'mail':
        if (!maxMail) {
          setMailAttributes({
            x: d.x,
            y: d.y,
            width: mailAttributes.width,
            height: mailAttributes.height,
            minWidth: mailAttributes.minWidth,
            minHeight: mailAttributes.minHeight
          })
        }
        break;
      case 'CD Player':
        if (!maxMusic) {
          setMusicAttributes({
            x: d.x,
            y: d.y,
            width: musicAttributes.width,
            height: musicAttributes.height,
            minWidth: musicAttributes.minWidth,
            minHeight: musicAttributes.minHeight
          })
        }
        break;
    }
  }

  function updateSize(r, window) {
    switch(window) {
      case 'about':
        setAboutAttributes({
          x: aboutAttributes.x,
          y: aboutAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: aboutAttributes.minWidth,
          minHeight: aboutAttributes.minHeight
        })
        setMaxAbout(false);
        break;
      case 'store':
        setStoreAttributes({
          x: storeAttributes.x,
          y: storeAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: storeAttributes.minWidth,
          minHeight: storeAttributes.minHeight
        })
        setMaxStore(false);
        break;
      case 'settings':
        setSettingsAttributes({
          x: settingsAttributes.x,
          y: settingsAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: settingsAttributes.minWidth,
          minHeight: settingsAttributes.minHeight
        })
        setMaxSettings(false);
        break;
      case 'documents':
        setDocumentsAttributes({
          x: documentsAttributes.x,
          y: documentsAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: documentsAttributes.minWidth,
          minHeight: documentsAttributes.minHeight
        })
        setMaxDocuments(false);
        break;
      case 'notepad':
        setNotepadAttributes({
          x: notepadAttributes.x,
          y: notepadAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: notepadAttributes.minWidth,
          minHeight: notepadAttributes.minHeight
        })
        setMaxNotepad(false);
        break;
      case 'pong':
        setPongAttributes({
          x: pongAttributes.x,
          y: pongAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: pongAttributes.minWidth,
          minHeight: pongAttributes.minHeight
        })
        setMaxPong(false);
        break;
      case 'music':
        setMusicAttributes({
          x: musicAttributes.x,
          y: musicAttributes.y,
          width: r.offsetWidth,
          height: r.offsetHeight,
          minWidth: musicAttributes.minWidth,
          minHeight: musicAttributes.minHeight
        })
        setMaxMusic(false);
        break;
    }
  }

  function updateNotepadText(value) {
    setNotepadText(value);
  };

  function updateWallpaper(value){
    setTheme(value);
    updateTheme(theme);
  }

  function updateLogout() {
    setLogout(true);
  }

  function cancelLogout() {
    setLogout(false);
  }

  function executeLogout() {
    setTheme('(None)')
    setStartup(true);
    setLogout(false);
    setTabs([]);
    setActive('')
    setShowAbout(false);
    setShowSettings(false);
    setShowDocuments(false);
    setShowNotepad(false);
    setShowPong(false);
    setShowMail(false);
    setShowMusic(true);
    setShowStore(true);
  }

  if (startup) {
    return (
      <Startup startup={startup} onChange={doneBooting} />
    )
  }
  else {
    return (
      <>
        {logout && (
          <LogoutScreen cancelLogout={cancelLogout} executeLogout={executeLogout}/>
        )}
        {showAbout && (
          <Window
            name='about'
            icon={about_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxAbout ? maxAttributes : aboutAttributes}
            maxed={maxAbout}
            allowMax={false}
            disableResize={true}
            allowMin={true}
          />
        )}

        {showStore && (
          <Window
            name='store'
            icon={store_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxStore ? maxAttributes : storeAttributes}
            maxed={maxStore}
            disableResize={mobile}
            allowMax={true}
            allowMin={true}
          />
        )}

        {showSettings && (
          <Window
            name='settings'
            icon={settings_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxSettings ? maxAttributes : settingsAttributes}
            maxed={maxSettings}
            theme={theme}
            updateWallpaper={updateWallpaper}
            allowMax={false}
            disableResize={true}
            allowMin={true}
          />
        )}

        {showDocuments && (
          <Window
            name='documents'
            icon={documents_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxDocuments ? maxAttributes : documentsAttributes}
            maxed={maxDocuments}
            disableResize={mobile}
            allowMax={true}
            allowMin={true}
          />
        )}

        {showNotepad && (
          <Window
            name='notepad'
            icon={notepad_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxNotepad ? maxAttributes : notepadAttributes}
            maxed={maxNotepad}
            allowMax={true}
            allowMin={true}
            text={notepadText}
            updateNotepadText={updateNotepadText}
            disableResize={mobile}
          />
        )}

        {showPong && (
          <Window
            name='pong'
            icon={pong_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxPong ? maxAttributes : pongAttributes}
            maxed={maxPong}
            allowMax={false}
            allowMin={true}
            disableResize={true}
          />
        )}

        {showMail && (
          <Window
            name='mail'
            icon={mail_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxMail ? maxAttributes : mailAttributes}
            maxed={maxMail}
            allowMax={false}
            allowMin={true}
            disableResize={true}
          />
        )}

        {showMusic && (
          <Window
            name='CD Player'
            icon={cd_icon}
            active={active}
            updateActive={updateActive}
            minimizeWindow={minimizeWindow}
            maxWindow={maxWindow}
            closeWindow={closeWindow}
            updateCoordinates={updateCoordinates}
            updateSize={updateSize}
            attributes={maxMusic ? maxAttributes : musicAttributes}
            maxed={maxMusic}
            allowMax={false}
            allowMin={false}
            disableResize={true}
            musicDefaultWidth={musicWidth}
            musicDefaultHeight={musicHeight}
          />
        )}

        <div style={first}>
          <a onClick={() => {openWindow('store');setActive('store')}}>
            <Icon name="Store" src={store_icon} desktop={true} />
          </a>
        </div>

        <div style={second}>
          <a onClick={() => {openWindow('CD Player');setActive('CD Player')}}>
            <Icon name="CD Player" src={cd_icon} desktop={true} />
          </a>
        </div>

        <div style={third}>
          <a onClick={() => {openWindow('documents');setActive('documents')}}>
            <Icon name="Documents" src={documents_icon} desktop={true} />
          </a>
        </div>

        <div style={fourth}>
          <a onClick={() => {openWindow('settings');setActive('settings')}}>
            <Icon name="Settings" src={settings_icon} desktop={true} />
          </a>
        </div>

        <div style={fifth}>
          <a onClick={() => {openWindow('about');setActive('about')}}>
            <Icon name="About" src={about_icon} desktop={true} />
          </a>
        </div>

        <div style={sixth}>
          <a onClick={() => {openWindow('notepad');setActive('notepad')}}>
            <Icon name="Notepad" src={notepad_icon} desktop={true} />
          </a>
        </div>

        <div style={seventh}>
          <a onClick={() => {openWindow('mail');setActive('mail')}}>
            <Icon name="Mail" src={mail_icon} desktop={true} />
          </a>
        </div>
        
        {!mobile && (
          <div style={eighth}>
            <a onClick={() => {openWindow('pong');setActive('pong')}}>
              <Icon name="Pong" src={pong_icon} desktop={true} />
            </a>
          </div>
        )}

        <AppBar
          openWindow={openWindow}
          updateActive={updateActive}
          tabs={tabs}
          active={active}
          updateLogout={updateLogout}
        />
      </>
    )
  }
}


ReactDOM.render(<App />, document.getElementById('root'));


