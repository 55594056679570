import React from 'react'
import styled from 'styled-components'
import WindowsIcon from './WindowsIcon'
import about_icon from '../images/about.png'
import settings_icon from '../images/settings.png'
import cd_icon from '../images/cd.png'
import documents_icon from '../images/documents.png'
import notepad_icon from '../images/notepad.png'
import shutdown_icon from '../images/shutdown.png'
import store_icon from '../images/store.png'
import pong_icon from '../images/pong.png'

const Menu = styled.div`
    position: absolute;
    margin-bottom: 33px !important;
    bottom: 0;
    width: 200px;
    background-color: rgb(206,208,207);
    color: rgb(5,6,8);
    border-left-color: rgb(255,255,255);
    border-top-color: rgb(255,255,255);
    box-shadow: rgb(223,224,227) 1px 1px 15px 2px inset, rgb(136,140,143) -1px -1px 0px 1px inset;
    font-size: 16px;
    border-style: solid;
    border-bottom-width: 2px;
    border-right-width: 1px;
    border-top-width: 1px;
    border-left-width: 1px;
    padding: 1px;
`

const MenuItem = styled.div`
    color: rgb(5, 6, 8);
    padding: 0.75rem 0.5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: white;
      background-color: #000080;
      cursor: pointer;
    }

    &:active{
      color: rgb(5, 6, 8);
      background-color: rgb(206,208,207);
    }
`

const Divider = styled.div`
  display: block;
  border-top: 2px solid #929292;
  border-bottom: 1px solid white;
`

export default function StartMenu (props) {
  const mobile = (window.innerWidth < 500)

  function openWindow(value) {
    props.openWindow(value);
  }

  function updateActive(value){
    props.updateActive(value);
  }

  function update(value){
    updateActive(value);
    openWindow(value);
  }

  function updateLogout() {
    props.updateLogout();
  }



  return(
    <>
      <Menu>
        <MenuItem onClick={() => update('CD Player')} ><WindowsIcon src={cd_icon} placement="menu" />CD Player</MenuItem>
        <MenuItem onClick={() => update('store')} ><WindowsIcon src={store_icon} placement="menu" />Store</MenuItem>
        {!mobile && (<MenuItem onClick={() => update('pong')} ><WindowsIcon src={pong_icon} placement="menu" />Pong</MenuItem>)}
        <MenuItem onClick={() => update('notepad')} ><WindowsIcon src={notepad_icon} placement="menu" />Notepad</MenuItem>
        <MenuItem onClick={() => update('documents')} ><WindowsIcon src={documents_icon} placement="menu" />Documents</MenuItem>
        <MenuItem onClick={() => update('settings')} ><WindowsIcon src={settings_icon} placement="menu" />Settings</MenuItem>
        <MenuItem onClick={() => update('about')} ><WindowsIcon src={about_icon} placement="menu" />About</MenuItem>
        <Divider />
        <MenuItem onClick={() => updateLogout()}><WindowsIcon src={shutdown_icon} placement="menu" />Shut Down...</MenuItem>
      </Menu>
    </>
  )
}
