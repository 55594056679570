import React, { useEffect } from 'react'
import updateTheme from '../hooks/updateTheme'
import ProgressBar from 'react-bootstrap/ProgressBar'
import styled from 'styled-components'
import startup_logo from '../images/startup_logo.png'

const Div = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: #6d7f88;
`

const LoadingDiv = styled.div`
  width: 35%;
  margin: auto;

  @media (max-width: 600px) {
    width: 75%;
    padding-bottom: 90px;
  }
`

const Logo = styled.img`
  width: 100%;
  padding-bottom: 50px;
  text-align: center;
  margin: auto;
  display: flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const EnterText = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-align: center;
  position: absolute;
  padding-top: 15px;
`

const ErrorText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-align: left;
  position: absolute;
  padding-top: 15px;
  width: 35%;

  @media (max-width: 600px) {
    width: 75%;
  }
`

export default function Startup (props) {
  const [progress, setProgress] = React.useState(0)
  const id = React.useRef(null)
  const [flash, setFlash] = React.useState(false)
  const [error, setError] = React.useState(false)
  const mobile = (window.innerWidth < 500)

  const clear = () => window.clearInterval(id.current)

  React.useEffect(() => {
    id.current = window.setInterval(() => {

      if (progress === 65) {
        setTimeout(function(){
            setProgress(progress + 5);
        }, 500);
      }

      if (progress !== 100 && progress !== 65) {
        setProgress(progress + 5);
      }
    }, 100)

    return clear
  }, [progress])


  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      if (progress > 95){setFlash(!flash)}
    }, 750);
    return () => clearInterval(interval);
  }, [progress, flash]);

  function handleKeyPress(e) {
    if (!mobile && progress === 100 && e.key === 'Enter') {
      props.onChange(false);
    }
  }

  function handleClickPress(e) {
    if (mobile && progress === 100) {
      props.onChange(false);
    }
  }

  useEffect(() => {
      document.addEventListener('keypress', handleKeyPress);
      document.addEventListener('click', handleClickPress);
      return () => {
          document.removeEventListener('keypress', handleKeyPress);
          document.removeEventListener('click', handleClickPress);
      };
  });


  return (
    <Div>
      <LoadingDiv>
        <Logo src={startup_logo} />
        <ProgressBar now={progress} />
        {progress === 100 && flash && (
          <EnterText>{mobile ? 'Click Anywhere to Log In' : 'Press Enter to Log In'}</EnterText>
        )}
      </LoadingDiv>
    </Div>
  )
}