import React from 'react'
import styled from 'styled-components'
import FontAwesome from './FontAwesome'

const Default = styled.button`
  box-sizing: border-box;
  background-color: rgb(206, 208, 207);
  color: rgb(5, 6, 8);
  border-left-color: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(5, 6, 8);
  border-bottom-color: rgb(5, 6, 8);
  box-shadow: rgb(223, 224, 227) 1px 1px 0px 1px inset, rgb(136, 140, 143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 30px;
  width: auto;
  font-size: 16px;
  border-style: solid;
  border-width: 2px;
  padding: 0px 0.5rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:active{
    border-left-color: rgb(5, 6, 8);
    border-top-color: rgb(5, 6, 8);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
    border-style: solid;
    border-width: 2px;
    padding-top: 0;
  }

  &:disabled {
    color: #8a8a8a;
    text-shadow: 1px 1px white;

    &:active{
      box-sizing: border-box;
      background-color: rgb(206, 208, 207);
      color: #8a8a8a;
      border-left-color: rgb(255, 255, 255);
      border-top-color: rgb(255, 255, 255);
      border-right-color: rgb(5, 6, 8);
      border-bottom-color: rgb(5, 6, 8);
      box-shadow: rgb(223, 224, 227) 1px 1px 0px 1px inset, rgb(136, 140, 143) -1px -1px 0px 1px inset;
      position: relative;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 30px;
      width: auto;
      font-size: 16px;
      border-style: solid;
      border-width: 2px;
      padding: 0px 0.5rem;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
`

const WindowBtn = styled.button`
  box-sizing: border-box;
  background-color: rgb(206, 208, 207);
  width: 100px;
  margin-right: 5px;
  padding-bottom: 2px;
  color: rgb(5, 6, 8);
  border-left-color: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(5, 6, 8);
  border-bottom-color: rgb(5, 6, 8);
  box-shadow: rgb(223, 224, 227) 1px 1px 0px 1px inset, rgb(136, 140, 143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 30px;
  font-size: 14px;
  border-style: solid;
  border-width: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &:focus {
    outline: 0;
  }

  &:active{
    border-left-color: rgb(5, 6, 8);
    border-top-color: rgb(5, 6, 8);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
    border-style: solid;
    border-width: 2px;
    padding-top: 0;
  }

  &:disabled {
    color: #8a8a8a;
    text-shadow: 1px 1px white;
  }
`

export default function Button (props) {
  const inWindow = props.inWindow;
  const FaIcon = props.fontAwesome;

  if (inWindow === true) {
    return (
      <>
        <WindowBtn
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.children}
          {props.text}
          {FaIcon !== 'undefined' && (
            <FontAwesome icon={FaIcon} />
          )}
        </WindowBtn>
      </>
    )
  }
  else {
    return (
      <>
        <Default
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.children}
          {props.text}
          {FaIcon !== 'undefined' && (
            <FontAwesome icon={FaIcon} />
          )}
        </Default>
      </>
    )
  }

}