/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React, { useState, setState, useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { Rnd } from 'react-rnd';
import Button from './Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWindowMinimize, faTimes, faSquare } from '@fortawesome/free-solid-svg-icons'
import capitalize from '../hooks/capitalize'
import WindowsIcon from './WindowsIcon'
import Settings from './Settings'
import Documents from './Documents'
import MusicPlayer from './MusicPlayer'
import Notepad from './Notepad'
import About from './About'
import Store from './Store'
import Game from './Pong'
import Mail from './Mail'

const Header = styled.div`
  width: 100%;
  height: 25px;
  background-color: #000080;
  border-left-color: rgb(255,255,255);
  border-top-color: rgb(255,255,255);
  box-shadow: rgb(223,224,227) -1px 1px 0px 1px inset, rgb(136,140,143) -1px 1px 0px 1px inset;
  border-style: solid;
  border-bottom-width: 0;
  border-right-width: 1px;
  border-top-width: 1px;
  border-left-width: 2px;
  display: inline-flex;
`

const MainWindow = styled.div`
  width: 100%;
  background-color: rgb(206,208,207);
  height: calc(100% - 25px);
  border-left-color: rgb(255,255,255);
  border-top-color: rgb(255,255,255);
  box-shadow: rgb(223,224,227) -1px 0px 0px 1px inset, rgb(136,140,143) -1px 0px 0px 1px inset;
  border-style: solid;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-width: 0;
  border-left-width: 2px;
  padding: 1px;
  `

const HeaderGroup = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  margin-right: 2px;

  span {
    float: left;
    color: white;
    padding: 0 5px;
    font-weight: 600;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

const HeaderBtn = styled.button`
  box-sizing: border-box;
  background-color: rgb(206,208,207);
  color: rgb(5,6,8);
  box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.5rem;
  border: none;
  width: 17px;
  height: 17px;
  top: -1px;
  font-size: 10px;
  font-weight: 900;
  margin-right: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;

  &:focus {
    outline: 0;
  }

  &:active{
    border-left-color: rgb(5, 6, 8);
    border-top-color: rgb(5, 6, 8);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
  }
`

const HeaderBtnMax = styled.button`
  box-sizing: border-box;
  background-color: rgb(206,208,207);
  color: rgb(5,6,8);
  box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.5rem;
  border: none;
  width: 17px;
  height: 17px;
  top: -1px;
  font-size: 10px;
  font-weight: 900;
  margin-right: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;

  &:focus {
    outline: 0;
  }

  &:active{
    border-left-color: rgb(5, 6, 8);
    border-top-color: rgb(5, 6, 8);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
  }
`

const HeaderBtnMin = styled.button`
  box-sizing: border-box;
  background-color: rgb(206,208,207);
  color: rgb(5,6,8);
  box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.5rem;
  border: none;
  width: 17px;
  height: 17px;
  top: -1px;
  font-size: 10px;
  font-weight: 900;
  margin-right: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;

  &:focus {
    outline: 0;
  }

  &:active{
    border-left-color: rgb(5, 6, 8);
    border-top-color: rgb(5, 6, 8);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
  }

  svg {
    padding-right: 2px;
  }
`

const activeStyle = {
  zIndex: 5,
  paddingBottom: '35px'
};

const inactiveStyle = {
  zIndex: 1,
  paddingBottom: '35px'
};

const maxedStyle = {
  zIndex: 5,
}

const closeX = {
  paddingRight: '1px'
};

export default function Window (props) {

  const name = props.name
  const handleId = name.split(' ').join('') + 'DragHandle'
  const [dragHandleClass, setDragHandleClass] = React.useState('')
  const wrapperRef = useRef(null);
  const x = props.attributes.x
  const y = props.attributes.y
  const width = props.attributes.width
  const height = props.attributes.height
  const minWidth = props.attributes.minWidth
  const minHeight = props.attributes.minHeight
  const icon = props.icon

  useEffect(() => {
    setDragHandleClass(document.getElementById(handleId).className.split(' ')[0]);
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClickComponent, false);
    return () => {
      document.removeEventListener("mousedown", handleClickComponent, false);
    };
  }, []);

  function handleClickComponent(event) {
    if (wrapperRef.current && wrapperRef.current.contains(event.target)) {
      props.updateActive(name)
    }
  };

  function minimizeWindow() {
    props.minimizeWindow(name);
  }

  function maxWindow() {
    props.maxWindow(name);
  }

  function closeWindow() {
    props.closeWindow(name);
  }

  function updateCoordinates(d) {
    props.updateCoordinates(d, name);
  }

  function updateSize(ref) {
    props.updateSize(ref, name)
  }

  function updateWallpaper(value) {
    props.updateWallpaper(value);
  }

  function updateNotepadText(value) {
    props.updateNotepadText(value);
  }

  function renderMain(name) {
    switch(name) {
      case 'settings':
        return (
          <Settings
            theme={props.theme}
            updateWallpaper={updateWallpaper}
            closeWindow={closeWindow}
          />
        )
      case 'CD Player':
        return (
          <MusicPlayer
            defaultWidth={props.musicDefaultWidth}
            defaultHeight={props.musicDefaultHeight}
          />
        )
      case 'documents':
        return (
          <Documents />
        )
      case 'notepad':
        return (
          <Notepad
            text={props.text}
            updateNotepadText={updateNotepadText}
          />
        )
      case 'about':
        return (
          <About />
        )
      case 'pong':
        return (
          <Game />
        )
      case 'mail':
        return (
          <Mail />
        )
      case 'store':
        return (
          <Store width={width} />
        )
    }
  }

  const resizeParams = props.disableResize ? null : {
    top:true,
    right:true,
    bottom:true,
    left:true,
    topRight:true,
    bottomRight:true,
    bottomLeft:true,
    topLeft:true
  }

  return (
    <>
      <div ref={wrapperRef}>
        <Rnd
          default={{
            x: x,
            y: y,
            width: width,
            height: height,
          }}
          enableResizing={resizeParams}
          onDragStop={(e, d) => { updateCoordinates(d) }}
          onResize={(e, direction, ref, delta, position) => { updateSize(ref) }}
          minWidth={minWidth}
          minHeight={minHeight}
          bounds='window'
          dragHandleClassName={dragHandleClass}
          style={props.maxed ? maxedStyle : props.active === name ? activeStyle : inactiveStyle}
        >
          <Header>
            <HeaderGroup id={handleId}>
              <span><WindowsIcon src={icon} placement="window" /> {capitalize(name)}</span>
              {props.allowMin && (
                <HeaderBtnMin onTouchEnd={() => minimizeWindow()} onClick={() => minimizeWindow()} >
                  <img src="data:image/gif;base64,R0lGODlhDAAKAIABAAAAAAQz/yH5BAEKAAEALAAAAAAMAAoAAAINjI+py+0PGZhU0RpNAQA7" height="9" />
                </HeaderBtnMin>
              )}
              {props.allowMax && (
                <HeaderBtnMax onTouchEnd={() => maxWindow()} onClick={() => maxWindow()}>
                  <img style={props.maxed ? closeX : null} src={props.maxed ? "data:image/gif;base64,R0lGODlhDAAKAIABAAAAAAQz/yH5BAEKAAEALAAAAAAMAAoAAAIWjI8ZwK3tEkDzQLbozVZX83HUKG1HAQA7" : "data:image/gif;base64,R0lGODlhDAAKAIABAAAAAAQz/yH5BAEKAAEALAAAAAAMAAoAAAIWDI4Ym732IngzMmqvthXm3klUo2RmAQA7"} height="9" />
                </HeaderBtnMax>
              )}
              <HeaderBtn onTouchEnd={() => closeWindow()} onClick={() => closeWindow()} >
                <img style={closeX} src="data:image/gif;base64,R0lGODlhDAAKAIABAAAAAAQz/yH5BAEKAAEALAAAAAAMAAoAAAIUjI8IoNy2ElxOhmgvtRgfr2WiWAAAOw==" height="9" />
              </HeaderBtn>
            </HeaderGroup>
          </Header>
          <MainWindow>
            {renderMain(name)}
          </MainWindow>
        </Rnd>
      </div>
    </>
  )

}

