import React from 'react'


export default function WindowsIcon (props) {
  const src = props.src

  const menuSize = {
    width: '33px',
    height: '33px',
    marginRight: '10px',
  }

  const windowSize = {
    width: '16px',
    height: '16px',
    marginRight: '4px',
    marginBottom: '1px',
    marginLeft: '1px'
  }

  const tabSize = {
    width: '18px',
    height: '18px',
    marginRight: '8px',
    marginLeft: '2px',
    marginTop: '1px'
  }

  return(
    <>
      <img src={src} style={props.placement === 'menu' ? menuSize : props.placement === 'tab' ? tabSize : windowSize} />
    </>
  )
}
