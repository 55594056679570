import React from 'react';
import styled from 'styled-components'
import '../index.css';

const Empty = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
`

const TextArea = styled.textarea`
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: white;
  margin: auto;
  font-size: 16px;
  overflow-x: hidden;
  resize: none;
  outline: none;
  border: none;
  padding: 10px;


  &::-webkit-scrollbar {
   width: 1em;
   height: 1em;
   background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    transform-origin: 0 0 0;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-size: 2px 2px, 2px 2px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
   background-color: rgb(206, 208, 207);
    color: rgb(5, 6, 8);
    border-left-color: rgb(255, 255, 255);
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(5, 6, 8);
    border-bottom-color: rgb(5, 6, 8);
    box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px 0px 0px 1px inset;
    position: relative;
    border: 1px;
  }

  focus {
    outline: 0;
  }
`

export default function Notepad (props) {
  const text = props.text

  const handleChange = (event) => {
      props.updateNotepadText(event.target.value);
  };

  return (
    <Empty>
      <TextArea value={text} onChange={handleChange} spellCheck='false'></TextArea>
    </Empty>
  )
}
