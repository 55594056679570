import React from 'react';
import styled from 'styled-components'
import ReactDOM from 'react-dom';
import '../index.css';
import Icon from './Icon'

import instagram from '../images/instagram.png'
import twitter from '../images/twitter.png'
import spotify from '../images/spotify.png'
import soundcloud from '../images/soundcloud.png'

const Empty = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export default function Documents (props) {

  return (
    <Empty>
      <Icon src={instagram} name="Instagram" link="https://www.instagram.com/big_desk_energy/" />
      <Icon src={twitter} name="Twitter" link="https://twitter.com/bigdeskenergy" />
      <Icon src={spotify} name="Spotify" link="https://open.spotify.com/playlist/5s8443tfYUq3LLARJwGeYP" />
      <Icon src={soundcloud} name="Soundcloud" link="https://soundcloud.com/user-505557456" />
    </Empty>
  )
}
