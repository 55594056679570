import React from 'react';
import styled from 'styled-components'
import ReactDOM from 'react-dom';
import '../index.css';
import Button from './Button'
import updateTheme from '../hooks/updateTheme'

import none_preview from '../images/none_preview.png'
import clouds_preview from '../images/clouds_preview.png'
import carved_stone_preview from '../images/carved_stone_preview.png'
import metal_links_preview from '../images/metal_links_preview.png'
import red_blocks_preview from '../images/red_blocks_preview.png'
import straw_mat_preview from '../images/straw_mat_preview.png'
import bubbles_preview from '../images/bubbles_preview.png'
import blue_rivets_preview from '../images/blue_rivets_preview.png'

const WindowDiv = styled.div`
  padding: 10px;
  margin: auto;
  position: relative;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const ButtonContainer = styled.div`
  bottom: 0;
  right: 0;
  text-align: center;
  position: absolute;
  padding: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const WallpaperContainer = styled.div`
  padding: 15px;
  width: 60%;
  margin: auto;
  text-align: center;
  margin-top: 15px;
  border: 1px solid white;
  box-shadow: rgb(136, 140, 143) -1px -1px 0px 1px, rgb(136, 140, 143) -1px -1px 0px 1px inset;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Label = styled.p`
  background-color: #ced0cf;
  position: absolute;
  top: -13px;
  padding: 0 3px;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`


const ScrollContainer = styled.div`
  height: 100px;
  overflow: scroll;
  background-color: white;
  width: 100%;
  margin: auto;
  font-size: 12px;
  overflow-x: hidden;
  border: 1px;
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  border-left-color: rgb(134, 137, 144);
  border-top-color: rgb(128, 128, 128);
  border-style: solid;
  box-shadow: rgb(223, 224, 227) 0px 1px 5px 0px inset, rgb(136, 140, 143) 0px 1px 0px 0px inset;


  &::-webkit-scrollbar {
   width: 1em;
   height: 1em;
   background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    transform-origin: 0 0 0;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-size: 2px 2px, 2px 2px;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
   background-color: rgb(206, 208, 207);
    color: rgb(5, 6, 8);
    border-left-color: rgb(255, 255, 255);
    border-top-color: rgb(255, 255, 255);
    border-right-color: rgb(5, 6, 8);
    border-bottom-color: rgb(5, 6, 8);
    box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px 0px 0px 1px inset;
    position: relative;
    border: 1px;
  }
`

const CompImg = styled.img`
  width: 50%;
  max-width: 250px;
  margin-bottom: 25px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Tab = styled.div`
  box-shadow: rgb(223, 224, 227) 1px 0px 0px 0px inset, rgb(136, 140, 143) -1px -1px 0px 0px inset;
  padding: 25px;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid white;
  border-top: 1px solid white;
  margin-top: 23px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

`

const List = styled.ul`
  list-style: none;
  text-align: left;
  padding-left: 0;
  margin-bottom: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  li {
    padding-left: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px solid white;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  li.active{
    background-color: #000080;
    color: white;
    border: 1px dotted yellow;
  }

  li:hover{
    cursor: auto !important;
  }
`

const TabMenu = styled.ul`
  display: inline-flex;
  margin-bottom: 0;
  list-style: none;
  padding: 0;
  font-size: 12px;
  position: absolute;
  top: 11px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  li {
    padding: 2px 5px;
  }

  li.active-first {
    background-color: #ced0cf;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: rgb(223,224,227) 1px 0px 0px 0px inset, rgb(136,140,143) -1px 0px 0px 0px inset;
  }
`

const Center = styled.div`
  text-align: center;
`

export default function Settings (props) {
  const [activeTheme, setActiveTheme] = React.useState(props.theme);
  const [activeTab, setActiveTab] = React.useState(0)

  const themes = {
    '(None)': none_preview,
    'Blue Rivets': blue_rivets_preview,
    'Bubbles': bubbles_preview,
    'Carved Stone': carved_stone_preview,
    'Clouds': clouds_preview,
    'Metal Links': metal_links_preview,
    'Red Blocks': red_blocks_preview,
    'Straw Mat': straw_mat_preview,
  }

  function updateWallpaper(value) {
    props.updateWallpaper(value);
  }

  function closeWindow() {
    props.closeWindow('settings');
  }

  return(
    <>
      <WindowDiv>
        <TabMenu>
          <li className="active-first">Background</li>
        </TabMenu>
        <Tab>
          <Center>
            <CompImg src={themes[activeTheme]} />
          </Center>
          <WallpaperContainer>
            <Label>Wallpaper</Label>
            <ScrollContainer>
              <List>
                {Object.keys(themes).map((theme) =>
                  <li key={theme} className={activeTheme === theme ? 'active' : ''} onClick={() => setActiveTheme(theme)}>{theme}</li>
                )}
              </List>
            </ScrollContainer>
          </WallpaperContainer>
        </Tab>
        <ButtonContainer>
          <Button text="OK" onClick={() => closeWindow()} inWindow={true} />
          <Button text="Apply" onClick={() => updateWallpaper(activeTheme)} inWindow={true} disabled={activeTheme === props.theme} />
        </ButtonContainer>
      </WindowDiv>
    </>
  )
}