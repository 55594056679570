import React, { useState, useEffect, useRef } from 'react';
import AudioMotionAnalyzer from 'audiomotion-analyzer';
import styled from 'styled-components';
import safari_active from '../images/safari_active.gif';
import safari_inactive from '../images/safari_inactive.gif';
import loader from '../images/loader.gif';
import Button from './Button';

// Styled components
const Contents = styled.div`
  display: flex;
  padding: 10px;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Title = styled.h2`
  font-size: 16px;
  padding-top: 10px;
`;

const Time = styled.h3`
  font-size: 14px;
  position: absolute;
  padding-top: 20px;
  padding-right: 10px;
  top: 0;
  right: 0;
`;

const SliderBar = styled.div`
  margin: 10px;
  background-color: white;
  height: 15px;
  position: relative;
`;

const ButtonDiv = styled.div`
  margin: 7px;
  padding-top: 20px;
  padding-bottom: 20px;  /* Add more space below the buttons */
`;

const VisualizerSpan = styled.div`
  float: right;
`;

const LoadingDiv = styled.div`
  width: 100%;
  text-align: center;
  font-size: 22px;
  padding-top: 20px;
`;

const Slider = styled.div`
  background-color: rgb(206, 208, 207);
  width: 12px;
  height: 25px;
  position: absolute;
  top: -5px;
`;

const Gif = styled.img`
  width: 100%;
  height: 145px;
`;

const Loader = styled.img`
  width: 100px;
  height: 100px;
  display: block;
  text-align: center;
  margin: 25px auto;
`;

const visualizerStyle = {
  marginTop: '-15px',
  transform: 'rotate(180deg) scaleX(-1)',
};

const visualizerStyleNone = {
  display: 'none',
};

const windowStyle = {
  maxWidth: '550px',
};

const windowStyleVisualizer = {
  maxWidth: '100%',
  paddingBottom: '0 !important',
};

const visualizerButtons = {
  paddingTop: '0px',
};

const mobileTitle = {
  width: '80%',
};


export default function MusicPlayer(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [stream, setStream] = useState(null);
  const [trackNumber, setTrackNumber] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [audioMotion, setAudioMotion] = useState(null);
  const [audioMotion2, setAudioMotion2] = useState(null);
  const sliderRef = useRef(null);
  const [visualizer, setVisualizer] = useState(false);
  const [prevTransform, setPrevTransform] = useState(null);
  const [accessToken, setAccessToken] = useState(null); // Store OAuth access token
  const [tokenExpiration, setTokenExpiration] = useState(0);  // Store token expiration timestamp

  
  const canvasWidth = window.innerWidth;
  const canvasHeight = (window.innerHeight - 100) / 2;
  const isSafari = window.safari !== undefined;
  const mobile = window.innerWidth < 500;
  const displayVisualizer = !(isSafari || mobile);

  const playlistId = '1044765946';
  const clientId = 'T4lvLhb7G4PPb9be2mvP4o6ODa5nOF1G';
  const clientSecret = 'aDMScPNFm1cNqoq1xmM6PLdpclozywjo';
  const proxyUrl = 'https://protected-mesa-83600-c5d8811d6af4.herokuapp.com/';

  useEffect(() => {
    async function fetchAccessToken() {
      // Use cached token if it's still valid
      if (accessToken && Date.now() < tokenExpiration) {
        return accessToken;
      }
    
      // Only fetch a new token if the cached one has expired
      try {
        const response = await fetch(proxyUrl + 'https://api.soundcloud.com/oauth2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
          },
          body: new URLSearchParams({
            client_id: clientId,
            client_secret: clientSecret,
            grant_type: 'client_credentials',
          }),
        });
    
        if (!response.ok) throw new Error('Failed to obtain OAuth token');
    
        const data = await response.json();
        setAccessToken(data.access_token);
        setTokenExpiration(Date.now() + (data.expires_in * 1000));  // Cache token with expiration time
        return data.access_token;
      } catch (error) {
        console.error("Error fetching access token:", error);
        return null;
      }
    }
  
    const fetchPlaylist = async (accessToken) => {
      try {
        const targetUrl = `https://api.soundcloud.com/playlists/${playlistId}`;
        const response = await fetch(proxyUrl + targetUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use OAuth token
            Accept: 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch playlist. Status: ${response.status} - ${response.statusText}`);
        }
  
        const result = await response.json();
        setIsLoaded(true);
        shuffleTracks(result.tracks); // Shuffle and use the tracks
      } catch (error) {
        setIsLoaded(true);
        setError(error);
      }
    };
  
    const init = async () => {
      const token = await fetchAccessToken();
      if (token) {
        await fetchPlaylist(token);
      } else {
        setIsLoaded(true);
      }
    };
  
    init();
  }, [playlistId, clientId, clientSecret]);
  
  // Automatically play the track once the token and stream are ready
  useEffect(() => {
    if (accessToken && stream) {      
      // Add a slight delay to ensure everything is ready
      setTimeout(() => {
        playTrack();
      }, 500);  // Delay of 2 seconds
    }
  }, [accessToken, stream]);
  
  

  function shuffleTracks(array) {
    let counter = array.length;
  
    while (counter > 0) {
      let index = Math.floor(Math.random() * counter);
      counter--;
      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }
  
    setTracks([...array]);
    if (array.length > 0) {
      const firstTrack = array[0];
      if (firstTrack && firstTrack.stream_url) {
        const streamUrl = firstTrack.stream_url;  // Use the stream_url returned by SoundCloud, no client_id
        setStream(streamUrl);
      } else {
        console.error("Track stream URL is missing, cannot set stream URL.");
      }
    }
  }
  


  function playTrack() {
    const player = document.getElementById('audio');
    const player2 = document.getElementById('audio2');
    
    // Stop any existing playback before starting new
    if (!player.paused) {
      player.pause();  // Stop the existing track if it's playing
    }
    if (displayVisualizer && !player2.paused) {
      player2.pause();  // Stop the visualizer audio if it's playing
    }
  
    if (!stream) {
      console.error("Stream URL is missing!");
      return;
    }
  
    if (!accessToken) {
      console.error("Access token is missing!");
      return;
    }
  
    // Fetch the stream URL with the OAuth token
    fetch(stream, {
      method: 'GET',
      headers: {
        'Authorization': `OAuth ${accessToken}`,  // Use the OAuth token for authorization
        'Accept': 'application/json',
      },
      redirect: 'follow'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to resolve stream URL. Status: ${response.status} - ${response.statusText}`);
        }
        return response.url; // Follow the redirect to the actual stream URL
      })
      .then(finalStreamUrl => {
        if (player.src !== finalStreamUrl) {
          player.src = finalStreamUrl;  // Set the final resolved stream URL
          if (displayVisualizer) player2.src = finalStreamUrl;
        }
  
        player.play()
          .then(() => {
            setIsActive(true);
          })
          .catch((err) => {
            console.error("Failed to play audio:", err);
          });
  
        if (displayVisualizer) {
          player2.play().catch((err) => {
            console.error("Visualizer audio failed to play:", err);
          });
        }
  
        // Initialize visualizer after a delay to ensure everything is ready
        if (displayVisualizer && !audioMotion) {
          setTimeout(() => {
            initializeVisualizer();
          }, 2000);  // Delay visualizer initialization for 2 seconds
        }
      })
      .catch(err => {
        console.error("Error fetching stream URL:", err);
      });
  }
  
  
  
  

  function pauseTrack() {
    const player = document.getElementById('audio');
    const player2 = document.getElementById('audio2');
  
    // Pause both the audio and the visualizer audio
    player.pause();
    if (displayVisualizer) {
      player2.pause();
    }
  
    // Set the active state to false
    setIsActive(false);
  }
  

  function prevTrack() {
    let trackNo = (trackNumber - 1 + tracks.length) % tracks.length; // Decrement track number and loop back if necessary
    setTrackNumber(trackNo);
  
    const prevTrack = tracks[trackNo];
    if (prevTrack && prevTrack.stream_url) {
      const streamUrl = prevTrack.stream_url;  // Use the stream_url from the track
      if (streamUrl !== stream) {  // Only set if it's a different stream
        setStream(streamUrl);
        setSeconds(0);  // Reset the timer for the previous track
        playTrack();  // Play the previous track
      }
    } else {
      console.error("Previous track stream URL is missing, cannot set stream URL.");
    }
  }
  
  
  

  function nextTrack() {
    let trackNo = (trackNumber + 1) % tracks.length; // Increment track number and loop back if necessary
    setTrackNumber(trackNo);
  
    const nextTrack = tracks[trackNo];
    if (nextTrack && nextTrack.stream_url) {
      const streamUrl = nextTrack.stream_url;  // Use the stream_url from the track
      if (streamUrl !== stream) {  // Only set if it's a different stream
        setStream(streamUrl);
        setSeconds(0);  // Reset the timer for the next track
        playTrack();  // Play the next track
      }
    } else {
      console.error("Next track stream URL is missing, cannot set stream URL.");
    }
  }
  
  
  

  function initializeVisualizer() {
    if (!audioMotion) {
      const audioMotionInstance = new AudioMotionAnalyzer(document.getElementById('audio-player'), {
        source: document.getElementById('audio'),
        start: true,
        width: canvasWidth,
        height: canvasHeight,
        gradient: 'prism',
        lineWidth: 0.5,
        fillAlpha: 0.5,
        barSpace: 0.3,
        mode: 3,
        showScale: false,
        showLeds: true,
      });
      setAudioMotion(audioMotionInstance);
    }
  
    if (!audioMotion2 && displayVisualizer) {
      const audioMotion2Instance = new AudioMotionAnalyzer(document.getElementById('audio-player2'), {
        source: document.getElementById('audio2'),
        start: true,
        width: canvasWidth,
        height: canvasHeight,
        gradient: 'prism',
        lineWidth: 0.5,
        fillAlpha: 0.5,
        barSpace: 0.3,
        mode: 3,
        minDecibels: -99,
        maxDecibels: -55,
        showScale: false,
        showLeds: true,
      });
      setAudioMotion2(audioMotion2Instance);
    }
  }
  

  function toggleVisualizer() {
    setVisualizer(!visualizer);
    const player2 = document.getElementById('audio2');
    player2.volume = visualizer ? 0.03 : 0;
    
    // Ensure both visualizers are initialized properly
    if (!audioMotion || !audioMotion2) {
      initializeVisualizer();
    }
  
    !visualizer ? enableVisualizer() : disableVisualizer();
  }
  

  function enableVisualizer() {
    const parent = document.getElementById('CDPlayerDragHandle').parentElement.parentElement;
    setPrevTransform(parent.style.transform);
    parent.style.transform = 'translate(0px,0px)';
    parent.style.width = window.innerWidth + 'px';
    parent.style.height = window.innerHeight - 34 + 'px';
    parent.style.paddingBottom = '0';
  }

  function disableVisualizer() {
    const parent = document.getElementById('CDPlayerDragHandle').parentElement.parentElement;
    parent.style.transform = prevTransform;
    parent.style.width = props.defaultWidth + 'px';
    parent.style.height = props.defaultHeight - 20 + 'px';
  }

  useEffect(() => {
    let interval = null;
    const player = document.getElementById('audio');
  
    if (isActive) {
      // Sync the slider with the actual audio current time
      interval = setInterval(() => {
        setSeconds(player.currentTime); // Set the current time of the audio
  
        // When the song finishes, automatically move to the next track
        if (player.currentTime >= player.duration) {
          nextTrack(); // Call nextTrack when the current song finishes
        }
      }, 1000); // Update every second
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval); // Clear interval when paused or stopped
    }
  
    return () => clearInterval(interval); // Clean up on component unmount or pause
  }, [isActive, seconds, trackNumber, tracks]);
  
  

  if (error) {
    return <LoadingDiv>CD was scratched — currently fixing and getting a new one.</LoadingDiv>;
  } else if (!isLoaded) {
    return (
      <LoadingDiv>
        Loading disc...
        <Loader src={loader} />
      </LoadingDiv>
    );
  } else {
    return (
      <div style={visualizer ? windowStyleVisualizer : windowStyle}>
        <audio id="audio" src={stream} crossOrigin="anonymous" />
        {displayVisualizer && <audio id="audio2" src={stream} crossOrigin="anonymous" />}
        {!displayVisualizer ? (
          <>
            {isActive ? <Gif src={safari_active} /> : <Gif src={safari_inactive} />}
          </>
        ) : (
          <>
            <div id="audio-player"></div>
            <div id="audio-player2" style={visualizer ? visualizerStyle : visualizerStyleNone}></div>
          </>
        )}
        {tracks.length > 0 && !visualizer && (
          <>
            <Contents>
              <Title style={mobile ? mobileTitle : null}>{tracks[trackNumber].title}</Title>
              <Time>
                {convertSeconds(seconds)} / {convertMilliseconds(tracks[trackNumber].duration)}
              </Time>
            </Contents>
            <SliderBar ref={sliderRef}>
                <div ref={sliderRef} className="slider-bar">
                  <Slider
                    style={{ left: calculateSlider(
                      seconds,
                      tracks[trackNumber].duration,
                      sliderRef.current ? sliderRef.current.offsetWidth : 525
                    ) }}
                  />
                </div>
            </SliderBar>
          </>
        )}
        <ButtonDiv style={visualizer ? visualizerButtons : null}>
          <Button fontAwesome="backward" disabled={trackNumber === 0} onClick={() => prevTrack()} />
          <Button fontAwesome="forward" onClick={() => nextTrack()} />
          <Button fontAwesome="pause" disabled={!isActive} onClick={() => pauseTrack()} />
          <Button fontAwesome="play" disabled={isActive} onClick={() => playTrack()} />
          {displayVisualizer && (
            <VisualizerSpan>
              <Button text={visualizer ? 'Disable Visualizer' : 'Enable Visualizer'} onClick={() => toggleVisualizer()} />
            </VisualizerSpan>
          )}
        </ButtonDiv>
      </div>
    );
  }

  function convertMilliseconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  function convertSeconds(secs) {
    const minutes = Math.floor(secs / 60);
    const seconds = (secs % 60).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  function calculateSlider(seconds, duration, width) {
    const elapsed = seconds;
    const total = duration / 1000;
    const offset = Math.floor((elapsed / total) * width) - 5;
    return offset.toString() + 'px';
  }
}