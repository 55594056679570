export default function uniqueWindows(self) {

  const result = [];
  const map = new Map();
  for (const item of self) {
    if(!map.has(item.name)){
      map.set(item.name, true);
      result.push({
        name: item.name,
        icon: item.icon
      });
    }
  }

  return result;
}