import React from 'react'
import styled from 'styled-components'

const Holder = styled.div`
  text-align: center;
  padding: 5px;
  margin: 0;
  width: 90px;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover{
    text-decoration: none;
  }
`

const Link = styled.a`
  &:hover{
    text-decoration: none;
    cursor: pointer;
  }
`

const Img = styled.img`
  width: 35px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover{
    text-decoration: none;
    cursor: pointer;
  }
`

const Name = styled.p`
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const DesktopName = styled.p`
  color: white;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`


export default function Icon (props) {
  const src = props.src
  const name = props.name
  const link = props.link

  return props.link ? (
    <>
      <Holder>
        <Link href={link} target="_blank">
          <Img src={src} />
          {props.desktop ? <DesktopName>{name}</DesktopName> : <Name>{name}</Name>}
        </Link>
      </Holder>
    </>
  ) : (
    <>
      <Holder>
        <Img src={src} />
        {props.desktop ? <DesktopName>{name}</DesktopName> : <Name>{name}</Name>}
      </Holder>
    </>
  )
}
