import React, { useEffect, useState, memo } from 'react'
import styled from 'styled-components'
import Button from './Button'
import StartMenu from './StartMenu'
import Tab from './Tab'
import formatTime from '../hooks/formatTime'
import logo from '../images/logo.png'
import WindowsIcon from './WindowsIcon'
import speaker_icon from '../images/speaker.png'

const Bar = styled.div`
  width: 100%;
  height: 35px;
  bottom: 0;
  position: absolute;
  background-color: rgb(206, 208, 207);
  z-index: 7;
  display: flex;

  & > * {
    margin: 3px;
  }
`

const TimeBar = styled.div`
  box-sizing: border-box;
  background-color: rgb(206, 208, 207);
  position: relative;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 30px;
  width: auto;
  font-size: 16px;
  border-style: solid;
  border-width: 2px;
  padding: 0px 1rem;
  border-left-color: rgb(5, 6, 8);
  border-top-color: rgb(5, 6, 8);
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
  border-style: solid;
  border-width: 2px;
  float: right;
  right: 0;
  position: fixed;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const logoStyle = {
  maxWidth: '30px',
  marginRight: '5px'
};


export default function AppBar (props) {

  const [time, setTime] = React.useState(formatTime());
  const [showMenu, setShowMenu] = React.useState(false)

  // from props
  const tabs = props.tabs
  const active = props.active

  const totalTabs = tabs.length

  setInterval(() => {
    setTime(formatTime());
  }, 3000);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
          document.removeEventListener('click', handleClickOutside);
      };
  });

  // close show menu on any click
  function handleClickOutside(e) {
    if (showMenu) {
      setShowMenu(false);
    }
  }

  function openWindow(value) {
    props.openWindow(value);
    setShowMenu(false);
  }

  function updateActive(value) {
    props.updateActive(value);
    setShowMenu(false);
  }

  function updateLogout() {
    props.updateLogout();
  }

  return (
    <Bar>
      <Button text="Start" onClick={toggleMenu}>
        <img src={logo} style={logoStyle} />
      </Button>
      {showMenu && (
        <StartMenu
          openWindow={openWindow}
          updateActive={updateActive}
          updateLogout={updateLogout}
        />
      )}

      {tabs.map((key, i) => (
        <Tab
          onClick={() => setShowMenu(false)}
          name={key.name}
          key={key.name}
          icon={key.icon}
          active={key.name === active ? true : false}
          updateActive={updateActive}
          openWindow={openWindow}
          totalTabs={totalTabs}
        />
      ))}

      <TimeBar>
        <WindowsIcon src={speaker_icon} placement="tab" />
        {time}
      </TimeBar>
    </Bar>
  )
}