import React from 'react';
import styled from 'styled-components'
import ReactDOM from 'react-dom';
import '../index.css';
import Button from './Button'

let x = ((window.innerWidth/2)-175).toString() + 'px'
let y = ((window.innerHeight/2)-75).toString() + 'px'

let root = document.getElementById("root");
root.style.height = '0px';


const Background = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.5;
    z-index: 8;
`

const Window = styled.div`
  z-index: 9;
  position: relative;
  width: 350px;
  transform: translate(${x}, ${y})
`

const HeaderBtn = styled.button`
  box-sizing: border-box;
  background-color: rgb(206,208,207);
  color: rgb(5,6,8);
  box-shadow: rgb(223,224,227) 1px 1px 0px 0px inset, rgb(136,140,143) -1px -1px 0px 1px inset;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0.5rem;
  border: none;
  width: 17px;
  height: 17px;
  top: -1px;
  font-size: 10px;
  font-weight: 900;
  margin-right: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: 0;
  }

  &:active{
    border-left-color: rgb(5, 6, 8);
    border-top-color: rgb(5, 6, 8);
    border-right-color: rgb(255, 255, 255);
    border-bottom-color: rgb(255, 255, 255);
    box-shadow: rgb(136, 140, 143) 1px 1px 0px 1px inset, rgb(223, 224, 227) -1px -1px 0px 1px inset;
  }
`

const Header = styled.div`
  width: 100%;
  height: 25px;
  background-color: #000080;
  border-left-color: rgb(255,255,255);
  border-top-color: rgb(255,255,255);
  box-shadow: rgb(223,224,227) -1px 1px 0px 1px inset, rgb(136,140,143) -1px 1px 0px 1px inset;
  border-style: solid;
  border-bottom-width: 0;
  border-right-width: 1px;
  border-top-width: 1px;
  border-left-width: 2px;
  display: inline-flex;
`

const MainWindow = styled.div`
  width: 100%;
  background-color: rgb(206,208,207);
  height: calc(100% - 25px);
  border-left-color: rgb(255,255,255);
  border-top-color: rgb(255,255,255);
  box-shadow: rgb(223,224,227) -1px 0px 0px 1px inset, rgb(136,140,143) -1px 0px 0px 1px inset;
  border-style: solid;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top-width: 0;
  border-left-width: 2px;
  padding: 15px;
  `

const HeaderGroup = styled.div`
  display: inline-block;
  width: 100%;
  text-align: right;
  margin-right: 2px;

  span {
    float: left;
    color: white;
    padding: 0 5px;
    font-weight: 600;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`

const ButtonDiv = styled.div`
  width: 100%;
  padding-top: 15px;

  Button {
    margin-right: 5px;
  }
`

const closeX = {
  paddingRight: '1px'
};


export default function LogoutScreen (props) {

  function cancelLogout() {
    props.cancelLogout();
  }

  function executeLogout(){
    props.executeLogout();
  }

  return(
    <>
    <Background />
    <Window>
      <Header>
        <HeaderGroup>
          <span>Shut Down</span>
          <HeaderBtn onClick={() => cancelLogout()} >
            <img style={closeX} src="data:image/gif;base64,R0lGODlhDAAKAIABAAAAAAQz/yH5BAEKAAEALAAAAAAMAAoAAAIUjI8IoNy2ElxOhmgvtRgfr2WiWAAAOw==" />
          </HeaderBtn>
        </HeaderGroup>
      </Header>
      <MainWindow>
        <p>Are you sure you'd like to abort desk energy?</p>
        <ButtonDiv>
          <Button text="Cancel" onClick={() => cancelLogout()} />
          <Button text="Shut Down" onClick={() => executeLogout()} />
        </ButtonDiv>
      </MainWindow>
    </Window>
    </>
  )
}